/* #### Generated By: http://font.download #### */

@font-face {
  font-family: 'Big John Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Big John Regular'), url('../fonts/big-john.woff') format('woff');
  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.auto-container {
  position: static;
  max-width: 1440px;
  padding: 0px 15px;
  margin: 0 auto;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: 'Big John Regular'
}

button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
  text-decoration: none !important;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #050612;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #92101F;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #92101F;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 655px !important;
  top: 5%;
}
.bridge-modals {
  max-width: 70% !important;
}
.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  text-align: center;
  position: relative;
  padding: 43px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  -o-backdrop-filter: blur(20px);
  -ms-backdrop-filter: blur(20px);
  -moz-backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 16px;
}

.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 35%;
  padding: 25px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}



@media(max-width: 1450px) {
  .auto-container {
    max-width: 1140px;
    padding: 0 30px;
  }
}

@media(max-width: 1280px) {
  .auto-container {
    padding: 0 50px;
  }
}

@media(max-width: 1199px) {
  .auto-container {
    padding: 0 75px;
  }
}
@media(max-width: 991px){
  .bridge-modals {
    max-width: 90% !important;
    margin: 0 auto !important;
 }
}
@media(max-width: 767px) {
  .auto-container {
    padding: 0 30px;
  }
}